.link-with-underline {
    text-decoration: underline;
}

.a-link-with-underline a {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none !important;
}
.no-underline:hover {
    text-decoration: none !important;
}
.no-underline:focus {
    text-decoration: none !important;
}
.no-underline:active {
    text-decoration: none !important;
}
.no-underline a {
    text-decoration: none !important;
}

/* Define the keyframes for the up and down movement */
@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-7px); /* Moves the element 20px upward */
    }
    100% {
      transform: translateY(0);
    }
}

.fade-in-animation {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s ease-out, transform 0.6s ease-out;
}

/* Final state when the element is in view */
.fade-in-animation.in-view {
    opacity: 1;
    transform: translateY(0);
}